import React from "react";
import Loader from "react-js-loader";
import { SolidBtn } from "../../style/common.style";
import { CommonColor } from "../../Utils/color";
import { CommonString } from "../../Utils/CommonString";
import { ROUND_LOADER_TYPE } from "../../Utils/constant";
export default function CustomButton(props) {
  return (
    <>
      <SolidBtn variant={CommonString.contained} onClick={props.onClick}>
        {props.icon ? (
          <span style={{ marginRight: 10 }}>{props.icon}</span>
        ) : (
          ""
        )}
        {props.isLoading ? (
          <Loader
            type={ROUND_LOADER_TYPE}
            bgColor={CommonColor.white}
            size={30}
            color={CommonColor.red}
            timeout={3000}
          />
        ) : (
          props.name
        )}
      </SolidBtn>
    </>
  );
}
