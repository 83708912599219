import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  ButtonForChat,
  ChatContainer,
  ChatMainContainer,
  Chip,
  CloseButtonForChat,
  FlexContainer,
  ListContainer,
  SearchContainer,
  TextWithDot,
  TextWithImgContainer,
} from "../../style/chat.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCapitalizeText,
  getDateFormat,
  getTimeFormat,
} from "../../Utils/utilitiFunction";
import { CommonColor } from "../../Utils/color";
import {
  Input,
  SearchButton,
  SearchInputForm,
} from "../../style/applicant.style";
import { Avatar } from "@mui/material";
import { PROFILE_BASEURL } from "../../Utils/constant";
import { SubmissionController } from "../../controller/SubmissionController";
import { changeSearchChatList } from "../../feature/store/SubmissionReduxStore";
import Lottie from "react-lottie";
import ArrowAnimation from "../ArrowAnimation";
import { NotificationIcon } from "../../style/menu.style";

export default function SocketBox() {
  const dispatch = useDispatch();
  const [search_text, setSearch_text] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const submissionController = new SubmissionController();
  const chat_list = useSelector(
    (state) => state.SubmissionReduxStore.chat_list
  );
  const search_chat_list = useSelector(
    (state) => state.SubmissionReduxStore.search_chat_list
  );

  function getColor(e) {
    let color;
    if (e === "PLEASE SUBMIT") {
      color = {
        bg_color: CommonColor.bg_green,
        color: CommonColor.green,
      };
      return color;
    } else if (e === "LOCKED") {
      color = {
        bg_color: CommonColor.bg_blue,
        color: CommonColor.blue,
      };
      return color;
    } else if (e === "IN TOUCH") {
      color = {
        bg_color: CommonColor.bg_yellow,
        color: CommonColor.bg_yellow_text_color,
      };
      return color;
    }
    color = {
      bg_color: CommonColor.primary_bg,
      color: CommonColor.primary_color,
    };
    return color;
  }
  async function onSearch(e) {
    dispatch(changeSearchChatList([]));
    e.preventDefault();
    var sendData = {
      search_query: search_text.trim(),
    };
    var res = await submissionController.SearchDataInChatBox(sendData);
    setIsSearching(false);
    dispatch(changeSearchChatList(res));
  }
  function getChatList() {
    if (search_text === undefined) return;
    if (search_text.length === 0) {
      return chat_list;
    }
    if (search_text.length >= 0 && !isSearching) {
      return search_chat_list;
    }
    return chat_list;
  }
  return (
    <>
      <React.Fragment>
        {/* <ButtonForChat onClick={() => setIsOpen(true)}> */}
        <NotificationIcon
          onClick={() => setIsOpen(true)}
          icon={CommonIcon.update}
        />
        {/* <ArrowAnimation /> */}
        {/* </ButtonForChat> */}
        <Drawer anchor={"right"} open={isOpen}>
          <ChatContainer>
            <SearchContainer>
              <SearchInputForm
                onSubmit={(e) => {
                  dispatch(changeSearchChatList([]));
                  onSearch(e);
                }}
              >
                <Input
                  type="text"
                  placeholder={"Search"}
                  value={search_text}
                  onChange={(e) => {
                    setIsSearching(true);
                    setSearch_text(e.target.value);
                  }}
                />
                <SearchButton
                  onClick={(e) => {
                    dispatch(changeSearchChatList([]));
                    onSearch(e);
                  }}
                  type="submit"
                >
                  <Icon icon={CommonIcon.search} />
                </SearchButton>
              </SearchInputForm>
            </SearchContainer>
            <CloseButtonForChat onClick={() => setIsOpen(false)}>
              <Icon icon={CommonIcon.close} />
            </CloseButtonForChat>
            <ListContainer>
              {getChatList().map((data) => (
                <ChatMainContainer>
                  <TextWithImgContainer>
                    <FlexContainer>
                      <span>
                        <b>
                          {data?.applicant_last_name === ""
                            ? getCapitalizeText(data?.applicant_first_name)
                            : getCapitalizeText(data?.applicant_first_name) +
                              " " +
                              getCapitalizeText(data?.applicant_last_name)}
                        </b>
                      </span>
                    </FlexContainer>
                    <FlexContainer>
                      <p>{getDateFormat(data?._created_at)}</p>
                      <p>{getTimeFormat(data?._created_at)}</p>
                      <Chip
                        style={{
                          color: getColor(data?.status).color,
                          background: getColor(data?.status).bg_color,
                        }}
                      >
                        {data?.status}
                      </Chip>
                    </FlexContainer>
                  </TextWithImgContainer>
                  {/* <hr /> */}
                  <div>
                    <span>{data?.position_title}</span>
                    <TextWithDot>
                      <Icon icon={CommonIcon.dot} />
                      <b>{data?.position_id}</b>
                    </TextWithDot>
                    {/* <span></span> */}
                  </div>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 5,
                    }}
                  >
                    <Avatar
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: 5,
                      }}
                      src={PROFILE_BASEURL + data?.recruiter_image}
                    />
                    {data?.recruiter_name}
                  </p>
                </ChatMainContainer>
              ))}
            </ListContainer>
          </ChatContainer>
        </Drawer>
      </React.Fragment>
    </>
  );
}
