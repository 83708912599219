import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import {
  ProfileBox,
  Avtar,
  ProfileBoxContainer,
  NotificationSpan,
  Notification,
} from "../../style/menu.style";
import { Divider } from "@mui/material";
import CustomTooltip from "../CommonComponet/Tooltip";
import {
  LogoutButtonContainer,
  ProfileUrlInUserDropdown,
  TextInProfile,
  TextInProfileCard,
  TextInProfileSpan,
  UserIconWithName,
  UserName,
} from "../../style/common.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import ReportController from "../../controller/ReportController";
import moment from "moment";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import { LogoutButtonIcon } from "../../style/Icon.style";
import { PROFILE_BASEURL } from "../../Utils/constant";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
const options = ["Log Out"];

export default function UserDropDown(props) {
  const reportController = new ReportController();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setuserName] = useState();
  const [userEmail, setuserEmail] = useState();
  const [submissionData, setsubmissionData] = useState();
  const [profileUrl, setprofileUrl] = useState();
  const open = Boolean(anchorEl);

  useEffect(() => {
    var comment = JSON.parse(
      localStorage.getItem(CommonStringForLocalStorage.user)
    );
    if (comment == null) {
      history.push("/");
      return;
    }
    setuserName(comment.user.name);
    setuserEmail(comment.user.email);
    setprofileUrl(comment.user.profile_image_url);
    getReportData(comment);
  }, []);

  const onClickIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onSelect = (e) => {
    if (e === "Log Out") {
      localStorage.removeItem(CommonStringForLocalStorage.token);
      localStorage.removeItem(CommonStringForLocalStorage.user);
      localStorage.removeItem(CommonStringForLocalStorage.view_status);
      history.push("/");
    }
    setAnchorEl(null);
  };

  async function getReportData(e) {
    var sendData = {
      recruiter_id: e.user._id,
      year: moment().year(),
    };
    var res_2 = await reportController.getYearSubmissionReport(sendData);
    setsubmissionData(res_2.data);
    return;
  }
  return (
    <Notification>
      <UserIconWithName onClick={onClickIcon}>
        <UserName>{userName}</UserName>
        <CustomTooltip
          title={CommonString.user}
          name={
            <Avtar>
              <ProfileUrlInUserDropdown
                src={
                  profileUrl !== undefined ? PROFILE_BASEURL + profileUrl : ""
                }
              />
            </Avtar>
          }
        />
      </UserIconWithName>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={onSelect}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            // mt: 1.5,
            "& .MuiAvatar-root": {
              width: 30,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 27,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ProfileBox>
          <Avtar>
            <ProfileUrlInUserDropdown
              src={profileUrl !== undefined ? PROFILE_BASEURL + profileUrl : ""}
            />
          </Avtar>
          <div>
            <p style={{ fontWeight: "500" }}>{userName}</p>
            <NotificationSpan>{userEmail}</NotificationSpan>
          </div>
        </ProfileBox>
        <hr />
        <ProfileBoxContainer>
          <TextInProfileCard>
            <TextInProfileSpan>
              {submissionData === undefined ? 0 : submissionData.SUBMISSION}
            </TextInProfileSpan>
            <TextInProfile>{CommonString.submission}</TextInProfile>
          </TextInProfileCard>
          <TextInProfileCard>
            <TextInProfileSpan>
              {submissionData === undefined ? 0 : submissionData.INTERVIEW}
            </TextInProfileSpan>
            <TextInProfile>{CommonString.interview}</TextInProfile>
          </TextInProfileCard>
          <TextInProfileCard>
            <TextInProfileSpan>
              {submissionData === undefined ? 0 : submissionData.OFFER}
            </TextInProfileSpan>
            <TextInProfile>{CommonString.offer}</TextInProfile>
          </TextInProfileCard>
          <TextInProfileCard>
            <TextInProfileSpan>
              {submissionData === undefined ? 0 : submissionData.PLACEMENT}
            </TextInProfileSpan>
            <TextInProfile>{CommonString.placement}</TextInProfile>
          </TextInProfileCard>
          {onCheckLevelAccessibility() ? (
            <></>
          ) : (
            <TextInProfileCard>
              <TextInProfileSpan>
                {submissionData === undefined ? 0 : submissionData.APPLICANT}
              </TextInProfileSpan>
              <TextInProfile>{CommonString.uploaded_applicant}</TextInProfile>
            </TextInProfileCard>
          )}
        </ProfileBoxContainer>
        <hr />
        {options.map((option) => (
          <LogoutButtonContainer key={option} onClick={() => onSelect(option)}>
            <LogoutButtonIcon icon={CommonIcon.log_out} rotate={3} />
            {option}
          </LogoutButtonContainer>
        ))}
      </Menu>
    </Notification>
  );
}
