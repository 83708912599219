import { createSlice } from "@reduxjs/toolkit";
import { checkMinusValue } from "../../Utils/utilitiFunction";
import { v4 as uuidv4 } from "uuid";

export const ClientReduxStore = createSlice({
  name: "ClientReduxStore",
  initialState: {
    temp_partner_data: [],
    is_in_percentage: true,
    selected_partner_list: [],
    selected_partner_tax_list: [],
    bill_rate: 0,
    poc_detail: [],
    poc_count: uuidv4(),
    poc_detail_display: [],
  },
  reducers: {
    changeITempPartnerData: (state, action) => {
      state.temp_partner_data = action.payload;
    },
    changeIsInPercentage: (state, action) => {
      state.is_in_percentage = action.payload;
    },
    onchangeSelectedPartnerList: (state, action) => {
      state.selected_partner_list = action.payload;
    },
    onchangeSelectedPartnerTaxList: (state, action) => {
      state.selected_partner_tax_list = action.payload;
    },
    onchangeBillrate: (state, action) => {
      state.bill_rate = action.payload;
      var tempTaxData = [];
      state.selected_partner_tax_list.map((tax) => {
        const foundOne = state.temp_partner_data.filter((itemX) => {
          return itemX.id === tax.partner_id;
        });
        var clientData = foundOne[0];
        var tempdata = tax;
        var _1099 = clientData?.is_in_percentage
            ? action.payload - (action.payload * clientData?.margin) / 100
            : action.payload - clientData?.margin,
          tempdata = {
            ...tax,
            _1099: checkMinusValue(_1099),
            w2_with_benifits: checkMinusValue(
              _1099 - (_1099 * clientData?.w2_with_benifit) / 100
            ),
            w2_without_benifit: checkMinusValue(
              _1099 - (_1099 * clientData?.w2_without_benifit) / 100
            ),
            w2_salarized: checkMinusValue(
              (_1099 - (_1099 * clientData?.w2_salarized) / 100) * 2080
            ),
            margin: "",
          };
        tempTaxData.push(tempdata);
      });
      state.selected_partner_tax_list = tempTaxData;
    },
    onUpdateSelectedPartnerTaxList: (state, action) => {
      var tempdatax = state.selected_partner_tax_list.map((tax) => {
        var tempdata = tax;
        var tempx;
        if (action.payload.id === tax.partner_id) {
          if ("_1099" in action.payload.data) {
            tempx = {
              _1099: action.payload.data._1099,
              w2_with_benifits: checkMinusValue(
                action.payload.data._1099 -
                  (action.payload.data._1099 *
                    action.payload.client.w2_with_benifit) /
                    100
              ),
              w2_without_benifit: checkMinusValue(
                action.payload.data._1099 -
                  (action.payload.data._1099 *
                    action.payload.client.w2_without_benifit) /
                    100
              ),
              w2_salarized: checkMinusValue(
                (action.payload.data._1099 -
                  (action.payload.data._1099 *
                    action.payload.client.w2_salarized) /
                    100) *
                  2080
              ),
            };
            tempdata = { ...tax, ...tempx };
          } else {
            tempdata = { ...tax, ...action.payload.data };
          }
        }
        return tempdata;
      });
      state.selected_partner_tax_list = tempdatax;
    },
    onChangePOCDetail: (state, action) => {
      state.poc_detail.push(action.payload);
    },
    onChangePOCCount: (state, action) => {
      state.poc_count = action.payload;
    },
    onUpdatePOCdetail: (state, action) => {
      var data = state.poc_detail.map(function (item) {
        return item.count === action.payload.count ? action.payload : item;
      });
      state.poc_detail = data;
    },
    onDisplayPocDetail: (state, action) => {
      state.poc_detail = action.payload;
    },
  },
});

export const {
  changeITempPartnerData,
  onchangeSelectedPartnerList,
  onchangeSelectedPartnerTaxList,
  onUpdateSelectedPartnerTaxList,
  onchangeBillrate,
  onChangePOCDetail,
  onUpdatePOCdetail,
  onChangePOCCount,
  onDisplayPocDetail,
} = ClientReduxStore.actions;

export default ClientReduxStore.reducer;
