import React, { useEffect, useState } from "react";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import { TYPE } from "../../Utils/constant";
import { DatePickerCalendar } from "../../style/report.style";
import FormInput from "../CommonComponet/FormInput";
import { FlexContainer } from "../../style/job.style";
import { CommonColor } from "../../Utils/color";
import CustomButton from "../CommonComponet/Button";
import { CommonButtonName, PlaceholderName } from "../../Utils/CommonString";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import { useSelector } from "react-redux";

export default function CallReportFilter(props) {
  const [type, setType] = useState(TYPE[0]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const hasAccess = onCheckLevelAccessibility();
  const user_data = useSelector((state) => state.counter.user_data);
  const [recruiter, setRecruiter] = useState({
    name: "All",
    _id: "123",
    is_recruiter: 1,
  });
  const temp_recruiter_list = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );

  useEffect(() => {
    setType(TYPE[1]);
    setRecruiter(user_data);
  }, [user_data]);
  function setCustomDate(dateObject, name) {
    if (name === "from_date") {
      setFromDate(dateObject);
      props.data.current = {
        ...props.data.current,
        from_date: dateObject,
        to_date: toDate,
      };
    }
    setToDate(dateObject);
    props.data.current = {
      ...props.data.current,
      from_date: fromDate,
      to_date: dateObject,
    };
  }

  return (
    <FlexContainer>
      {onCheckLevelAccessibility() ? (
        <SelectTextFields
          style={{ width: 150, marginRight: 5, marginLeft: 10 }}
          placeholder={"Recruiter"}
          disabled={!hasAccess}
          value={recruiter}
          onChange={(e) => {
            props.RecruiterValueSet(e);
            setRecruiter(e);
          }}
          options={hasAccess ? temp_recruiter_list : [recruiter]}
          getOptionLabel={(option) => (option === null ? "" : option.name)}
        />
      ) : (
        <></>
      )}
      <SelectTextFields
        style={{
          width: 150,
          marginRight: 5,
          marginLeft: onCheckLevelAccessibility() ? 0 : 5,
        }}
        placeholder={PlaceholderName.duration}
        value={type}
        onChange={(value) => {
          props.TypeSetter(value);
          setType(value);
        }}
        options={TYPE}
        getOptionLabel={(option) => (option === null ? "" : option)}
      />
      {type === TYPE[4] ? (
        <>
          <DatePickerCalendar
            onChange={(e, value) => {
              setFromDate(value.validatedValue[0]);
              setCustomDate(value.validatedValue[0], "from_date");
            }}
            value={fromDate}
            render={
              <FormInput
                style={{ width: 150, background: CommonColor.white }}
                placeholder="From Date"
              />
            }
          />
          <div style={{ marginLeft: 5 }}>
            <DatePickerCalendar
              onChange={(e, value) => {
                setToDate(value.validatedValue[0]);
                setCustomDate(value.validatedValue[0], "to_date");
              }}
              value={toDate}
              render={
                <FormInput
                  style={{
                    width: 150,
                    background: CommonColor.white,
                    marginRight: 5,
                  }}
                  placeholder="To Date"
                />
              }
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {type === TYPE[5] || type === TYPE[6] || type === TYPE[4] ? (
        <></>
      ) : (
        <DatePickerCalendar
          style={{ width: 150, background: CommonColor.white, marginRight: 5 }}
          range={type === TYPE[4]}
          weekPicker={type === TYPE[2]}
          onlyMonthPicker={type === TYPE[1]}
          onlyYearPicker={type === TYPE[0]}
          onChange={(e) => props.setDate(e, type)}
          value={props.date}
          maxDate={new Date()}
          render={
            <FormInput
              style={{ width: 150, background: CommonColor.white }}
              value={""}
              placeholder="Select Date"
            />
          }
        />
      )}
      <div style={{ marginLeft: 5 }}>
        <CustomButton
          name={CommonButtonName.apply}
          onClick={() => props.getList()}
        />
      </div>
      {/* <OutlinedButtonContainer>
        <CustomButton name={CommonButtonName.clear} onClick={() => reset()} />
      </OutlinedButtonContainer> */}
    </FlexContainer>
  );
}
