import React from "react";
import { LoaderContainer } from "../../style/common.style";
import loader from "../../assets/images/loader3.json";
import Lottie from "react-lottie";
export default function SearchLoader(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <LoaderContainer is_applicant={props.is_applicant}>
      <Lottie options={defaultOptions} height={40} width={40} />
    </LoaderContainer>
  );
}
