import * as axios from "axios";
import { BASE_URL } from "../../Utils/constant";

export default class ApiUtils {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = BASE_URL;
    }
    init = () => {
        let headers = {
            Accept: "application/json",
        };
        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }
        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });
        return this.client;
    };
}