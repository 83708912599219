import { createSlice } from "@reduxjs/toolkit";

export const DiceStore = createSlice({
  name: "DiceStore",
  initialState: {
    searchedLocation: [],
  },
  reducers: {
    changeSearchedLocation: (state, action) => {
      state.searchedLocation.push(action.payload);
    },
    onClearAllLocation: (state, action) => {
      state.searchedLocation = action.payload;
    },
  },
});

export const { changeSearchedLocation,onClearAllLocation } = DiceStore.actions;

export default DiceStore.reducer;
