import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  DATE_FORMATE,
  STATUS_FOR_ADDITIONAL_INFO,
  SUBMISSION_STATUS,
  SUBMISSION_STATUS_FOR_MANAGER,
  SUBMISSION_STATUS_FOR_RECRUITER,
  SUBMISSION_STATUS_IN_TOUCH,
} from "../../Utils/constant";
import moment from "moment";
import { Icon } from "@iconify/react";
import RowText from "./view_component/RowText";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import {
  STATUS_ALREADY_UPDATED,
  UPDATE_SUBMISSION_STATUS_SUCCESS,
} from "../../Utils/alertMessage";
import {
  changeInitialLoader,
  onUpdateStatus,
} from "../../feature/store/ReduxStore";
import CustomPDFViewer from "../CustomPDFViewer";
import BaseModal from "../Modal/BaseModal";
import AddInterviewDetail from "../Modal/AddInterviewDetail";
import CustomSubTitle from "../CommonComponet/SubTital";
import {
  SubmissionController,
  getFormTitle,
} from "../../controller/SubmissionController";
import {
  changeInterviewDetails,
  changeIsUpdate,
  changeSubmissionStatus,
  changeSubmissionStatusList,
  onChangeSelectedSubmissionData,
  onUpdateSearchStatus,
} from "../../feature/store/SubmissionReduxStore";
import {
  AlertMessage,
  AlertTitle,
  CommonString,
  CommonStringForApplicant,
  CommonStringForInterviewDetails,
  CommonStringForJob,
  CommonStringForProjectDetails,
} from "../../Utils/CommonString";
import {
  OutlinedBtnForAddPartner,
  Title,
  ViewResumeText,
  ViewResumeTExtContainer,
} from "../../style/common.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import SelectPartner from "../Modal/SelectPartner";
import { confirmAlert } from "react-confirm-alert";
import UpdateStatusConfirmation from "../Modal/UpdateStatusConfirmation";
import {
  changeIsSubmission,
  onUpdateOfferDetilsBoolean,
} from "../../feature/store/JobReduxStore";
import LottieAnimation from "../LottieAnimation";
import { CommonColor } from "../../Utils/color";
import {
  IsOpenAlertBox,
  convertTo12HourFormat,
  getClientName,
  getFieldNameByInterviewModeInInterview,
  getRecruiters,
  onCheckLevelAccessibility,
} from "../../Utils/utilitiFunction";
import AddOFferDetails from "../Modal/AddOFferDetails";
import {
  Table,
  TableRow,
  TableTd,
  TableTh,
  TitleWithIcon,
} from "../../style/table.styled";
import AddProjectDetail from "../Modal/AddProjectDetail";
import StatusTimeline from "../Status/StatusTimeline";
import { ButtonContainer } from "../../style/status.style";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import AlertConfirmation from "../CommonComponet/alertConfirmation/alertConfirmation";
import RowTextHtml from "./view_component/RowTextHtml";
import TextWithIcon from "../CommonComponet/TextWithIcon";

export default function SubmissionViewDetails(props) {
  const submissionController = new SubmissionController();
  const isVisible = useSelector((state) => state.counter.is_visible);
  const dispatch = useDispatch();
  const is_submission = useSelector(
    (state) => state.JobReduxStore.is_submission
  );
  const project_details = useSelector(
    (state) => state.SubmissionReduxStore.project_details
  );
  const selected_data = useSelector(
    (state) => state.SubmissionReduxStore.selected_submission_data
  );
  const [isOpenAlertBox, setIsOpenAlertBox] = useState(false);
  const alert = useAlert();
  // const [isLoading, setisLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState();
  const [open, setOpen] = useState(false);
  const [isopenModal, setisOpenModal] = useState(false);
  const [modalStatus, setmodalStatus] = useState();
  const [selectedPartner, setSelectedPartner] = useState();
  const [partnerList, setPartnerList] = useState([]);
  const [statusContent, setStatusContent] = useState({});
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  var interview_details = selected_data?.interviewData?.data;
  var offer_details =
    selected_data?.offer_details === undefined
      ? null
      : selected_data?.offer_details[0];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getData();
    getDataForSubmissionStatus(selected_data?.status);
    submissionController.getProjectCompletedDetail(selected_data);
  }, []);

  async function getData() {
    setIsLoading(true);
    var sub_id =
      props.data._id === undefined ? props.data.submitions_id : props.data._id;
    var res = await submissionController.getStatusDataById({
      _id: sub_id,
    });
    dispatch(onChangeSelectedSubmissionData(res?.data[0]));
    setIsLoading(false);
  }
  function isCheckModalStatus(status, e) {
    setSubmissionStatus(status);
    if (selected_data.status === status) {
      alert.show(STATUS_ALREADY_UPDATED, { type: CommonString.info });
      return;
    }
    if (
      (STATUS_FOR_ADDITIONAL_INFO.includes(status) && e !== null) ||
      e !== undefined
    ) {
      setmodalStatus(status);
      setisOpenModal(true);
      return;
    }
    setStatusContent({ status: modalStatus, e: e });
    onSubmit(status, e);
  }
  async function onSubmit(status, e, res) {
    setIsOpenAlertBox(true);
    setStatusContent({ status: modalStatus, e: e, res: res });
  }
  async function onAddSubmissionStatus(status, data, offer_res) {
    try {
      var sendData = {
        _id: selected_data._id,
        position_id: selected_data.client._id,
        submitions_id: selected_data._id,
        status: status,
        applicant_name: `${selected_data?.applicant.first_name} ${selected_data?.applicant.last_name}`,
        applicant_first_name: selected_data?.applicant.first_name,
        applicant_id: selected_data?.applicant._id,
        applicant_last_name: selected_data?.applicant.last_name,
        department: selected_data?.parentJob.department,
        job_id: selected_data?.parentJob._id,
        child_job_id: selected_data?.childJob?._id,
        position_title: selected_data?.parentJob.position_title,
        job_name: selected_data?.parentJob.position_title,
        partner_name: selected_data.partner.name,
        call_1_status: selected_data.call_1_status,
        call_2_status: selected_data.call_2_status,
        call_3_status: selected_data.call_3_status,
        recruiter_name: selected_data.recruiter.name,
        recruiter_email:
          selected_data?.recruiter === null
            ? ""
            : selected_data?.recruiter?.email,
        recruiter_id: selected_data.recruiter._id,
        client_id: selected_data.client._id,
        client_name: selected_data.client.name,
        data: data ?? [],
        comment: data?.comment ? data.comment : content,
      };
      const res = await submissionController.changeStatusSubmission(sendData);
      dispatch(changeSubmissionStatus(status));
      if (res.data.status === 200) {
        props.handleClose();
        dispatch(changeSubmissionStatusList(SUBMISSION_STATUS_IN_TOUCH));
        getDataForSubmissionStatus(isVisible, selected_data.status);
        dispatch(onUpdateOfferDetilsBoolean(false));
        dispatch(
          onUpdateStatus({
            ...selected_data,
            status: status,
            interview_detail: data,
          })
        );
        dispatch(
          onUpdateSearchStatus({
            ...selected_data,
            status: status,
            interview_detail: data,
          })
        );
        alert.show(status + UPDATE_SUBMISSION_STATUS_SUCCESS, {
          type: CommonString.success,
        });
        props.onRefresh();
        if (status === CommonString.uppercase_submission) {
          dispatch(changeIsSubmission(true));
          setTimeout(() => {
            dispatch(changeIsSubmission(false));
          }, 3000);
        }
        alert.show(status + UPDATE_SUBMISSION_STATUS_SUCCESS, {
          type: CommonString.success,
        });
        setisOpenModal(false);
        dispatch(changeInitialLoader(false));
        props.handleClose();
        return;
      } else if (res.data.status === 202) {
        alert.show("Position Already covered", {
          type: CommonString.info,
        });
        dispatch(changeInitialLoader(false));
        return;
      } else {
        alert.show(
          "Error While updating " + status + " status, please try again.",
          {
            type: CommonString.error,
          }
        );
        dispatch(changeInitialLoader(false));
      }
      return;
    } catch (error) {
      dispatch(changeInitialLoader(false));
    }
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function onSubmitOfferDetail(e, res) {
    if (res.status === 200) {
      setisOpenModal(false);
      onSubmit(modalStatus, e, res);
      return;
    } else {
      setisOpenModal(true);
      alert.show("error while add offer detail", {
        type: CommonString.error,
      });
    }
  }
  function getFormView() {
    if (modalStatus === SUBMISSION_STATUS.LOCKED) {
      return <SelectPartner onSubmit={(e) => onSubmit(modalStatus, e)} />;
    } else if (modalStatus === SUBMISSION_STATUS.INTERVIEW) {
      return (
        <AddInterviewDetail
          handleClose={() => setisOpenModal(!isopenModal)}
          AddInterviewDetails={(e) => {
            setisOpenModal(false);
            onSubmit(modalStatus, e);
          }}
        />
      );
    } else if (
      modalStatus === SUBMISSION_STATUS.OFFER ||
      modalStatus === SUBMISSION_STATUS.PLACEMENT
    ) {
      return (
        <AddOFferDetails
          modalStatus={modalStatus}
          onAddOfferDetail={(e, res) => {
            onSubmitOfferDetail(e, res);
          }}
          handleClose={() => setisOpenModal(!isopenModal)}
          submission_id={selected_data?._id}
        />
      );
    } else {
      return (
        <AddProjectDetail
          onSubmit={onSubmit}
          handleClose={() => setisOpenModal(!isopenModal)}
          submission_id={selected_data?._id}
          submissionStatus={submissionStatus}
        />
      );
    }
  }
  function getDataForSubmissionStatus(selectedData) {
    if (selectedData === SUBMISSION_STATUS.IN_TOUCH) {
      dispatch(changeSubmissionStatusList(SUBMISSION_STATUS_IN_TOUCH));
      return;
    } else if (onCheckLevelAccessibility()) {
      dispatch(changeSubmissionStatusList(SUBMISSION_STATUS_FOR_MANAGER));
      return;
    }
    dispatch(changeSubmissionStatusList(SUBMISSION_STATUS_FOR_RECRUITER));
  }
  function onSubmitPartner() {
    confirmAlert({
      customUI: ({ onClose }) => (
        <AlertConfirmation
          onClose={() => onClose()}
          onApprove={() => onSelectPartnerAndSave(onClose())}
          title={AlertTitle.change_partner}
          subtitle={AlertMessage.partner_change_message}
          subtitleKey={""}
          content={""}
          setContent={""}
          enableInput={true}
        />
      ),
    });
  }
  async function onSelectPartnerAndSave(onClose) {
    try {
      if (
        (selectedPartner.partner._id,
        selected_data.partner._id == selectedPartner.partner._id)
      ) {
        alert.show("You cant change same partner.", {
          type: CommonString.error,
        });
        onClose();
        return "You cant chnage same partner";
      }
      var sendData = {
        submission_id: selected_data._id,
        child_job_id: selectedPartner._id,
        partner_id: selectedPartner.partner._id,
      };
      var res = await submissionController.onUpdatePartnerInSubmission(
        sendData
      );
      alert.show("Update Successfully.", { type: CommonString.success });
      onClose();
    } catch (error) {
      console.error(error);
    }
  }
  function GetViewForDetails(props) {
    return props?.value === "" ? (
      <></>
    ) : (
      <TableRow>
        <TableTd>
          <b> {props.name}</b>
        </TableTd>
        <TableTd>{props.value}</TableTd>
      </TableRow>
    );
  }
  function getCityName(data) {
    if (data === undefined) return;
    if (
      data.state === " " &&
      data.zip === "" &&
      (data.city === " " || data.city === "")
    ) {
      return "";
    }
    if (data.state === " " && data.zip !== "" && data.city === " ") {
      return data.zip;
    }
    if (data.state !== " " && data.zip !== "" && data.city === " ") {
      return `${data.state}, ${data.zip}`;
    }
    if (data.state !== " " && data.zip === "" && data.city !== " ") {
      return `${data.city}, ${data.state}`;
    }
    if (data.state === " " && data.zip === "" && data.city !== " ") {
      return `${data.city}`;
    }
    return `${data.city}, ${data.state.trim()}, ${data.zip}`;
  }
  return isLoading ? (
    <></>
  ) : (
    <>
      <UpdateStatusConfirmation
        isLoading={false}
        status={submissionStatus}
        possition_id={selected_data?.parentJob?.client_position_id}
        onClose={() => setIsOpenAlertBox(false)}
        onDelete={() => {
          onAddSubmissionStatus(
            submissionStatus,
            statusContent.e,
            statusContent.res
          );
          setIsOpenAlertBox(false);
        }}
        isOpenAlertBox={isOpenAlertBox}
        content={content}
        setContent={(e) => setContent(e)}
        enableInput={IsOpenAlertBox(submissionStatus)}
      />
      <LottieAnimation isVisible={is_submission} />
      <StatusTimeline
        setIsOpenAlertBox={(e) => setIsOpenAlertBox(e)}
        status={selected_data?.status}
        onClick={(status) => {
          setSubmissionStatus(status);
          isCheckModalStatus(status);
        }}
      />
      <ButtonContainer
        visible={onCheckLevelAccessibility() && partnerList.length !== 0}
      >
        <SelectTextFields
          placeholder="Select Partner"
          value={selectedPartner}
          style={{ width: 200 }}
          onChange={(value) =>
            setSelectedPartner(
              partnerList.filter((data) => data._id === value)[0]
            )
          }
          options={partnerList}
          getOptionLabel={(option) => option.partner.name}
        />
        <div style={{ marginLeft: 10 }}></div>
        <OutlinedBtnForAddPartner onClick={() => onSubmitPartner()}>
          Change Partner
        </OutlinedBtnForAddPartner>
      </ButtonContainer>
      <BaseModal
        isLoading={isLoading}
        open={isopenModal}
        formTitle={getFormTitle(modalStatus)}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        {getFormView()}
      </BaseModal>
      <>
        <Grid container style={{ marginTop: 60 }}>
          <Grid
            item
            xs={12}
            md={6}
            style={{ borderRight: ".5px solid lightgrey", paddingRight: 10 }}
          >
            <Title>
              {selected_data?.parentJob?.position_title} -{" "}
              <span>
                {selected_data?.parentJob?.client_position_id ||
                  CommonString.na}
              </span>
              {` || ${getClientName(selected_data?.client?.name)}`}
            </Title>
            <TextWithIcon
              icon={CommonIcon.bag}
              text={selected_data?.parentJob?.department}
            />
            <TextWithIcon
              icon={CommonIcon.map}
              text={`${selected_data?.parentJob?.job_location} ${
                selected_data?.parentJob?.zip_code === 0 ||
                selected_data?.parentJob?.zip_code === ""
                  ? ""
                  : `, ${selected_data?.parentJob?.zip_code}`
              }`}
            />
            <TextWithIcon
              icon={CommonIcon.clock}
              text={selected_data?.parentJob?.project_duration}
            />
            <hr />
            <Grid container spacing={1}>
              <Grid item md={6} style={{ paddingRight: 15 }}>
                <Table className={"t-a-Left"}>
                  <GetViewForDetails
                    color={CommonColor.secondary_color}
                    name={CommonStringForJob.submitted_by}
                    value={selected_data?.recruiter?.name}
                  />
                  <GetViewForDetails
                    color={CommonColor.secondary_color}
                    name={CommonStringForJob.submission_tax_term}
                    value={selected_data?.tax_term}
                  />
                  <GetViewForDetails
                    color={CommonColor.secondary_color}
                    name={CommonStringForJob.submission_pay_rate}
                    value={"$" + selected_data?.payrate?.replace("$", "")}
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.due_date}
                    value={
                      selected_data?.childJob?.due_date === null
                        ? ""
                        : moment(selected_data?.childJob?.due_date).format(
                            DATE_FORMATE
                          )
                    }
                  />
                  <GetViewForDetails
                    color={CommonColor.secondary_color}
                    name={CommonStringForJob.max_submission}
                    value={selected_data?.childJob?.max_submission}
                  />
                  <GetViewForDetails
                    color={CommonColor.secondary_color}
                    name={CommonStringForJob.no_of_submission}
                    value={selected_data?.childJob?.no_of_submission}
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.open_date}
                    value={
                      selected_data?.childJob?.open_date === null
                        ? ""
                        : moment(selected_data?.childJob?.open_date).format(
                            DATE_FORMATE
                          )
                    }
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.contact_manager}
                    value={
                      selected_data?.parentJob?.poc_name ?? CommonString.na
                    }
                  />
                </Table>
              </Grid>
              <Grid
                item
                md={6}
                style={{ borderLeft: "1px solid lightgrey", paddingLeft: 15 }}
              >
                <Table className={"t-a-Left"}>
                  <GetViewForDetails
                    color={CommonColor.secondary_color}
                    name={CommonStringForJob.total_opening}
                    value={selected_data?.childJob?.total_opening}
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.job_mode}
                    value={selected_data?.parentJob?.job_mode}
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.job_type}
                    value={selected_data?.parentJob?.job_type}
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.interview_mode}
                    value={selected_data?.parentJob?.interview_mode}
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.experience}
                    value={
                      selected_data?.parentJob?.require_year_experience +
                        CommonString.years ?? CommonString.na
                    }
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.required_education}
                    value={
                      selected_data?.parentJob?.require_education ??
                      CommonString.na
                    }
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.assign_to}
                    value={getRecruiters(selected_data)}
                  />
                  <GetViewForDetails
                    name={CommonStringForJob.partner_name}
                    value={getClientName(selected_data?.partner?.name)}
                  />
                </Table>
              </Grid>
            </Grid>
            <hr />
            <RowTextHtml
              title={CommonStringForJob.job_description}
              id={CommonStringForJob.job_description}
              value={
                selected_data?.parentJob?.position_description ??
                CommonString.na
              }
            />
            <RowTextHtml
              title={CommonStringForJob.skill_matrix}
              id={CommonStringForJob.skill_matrix}
              value={selected_data?.parentJob?.skill_matrix ?? CommonString.na}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingLeft: 10 }}>
            {!open ? (
              <>
                <Title>{selected_data.full_name}</Title>
                <TextWithIcon
                  icon={CommonIcon.bag}
                  text={selected_data?.applicant?.designation}
                />
                <TextWithIcon
                  icon={CommonIcon.email}
                  text={selected_data?.applicant?.primary_email}
                />
                <TextWithIcon
                  icon={CommonIcon.call}
                  text={selected_data?.applicant?.phone}
                />
                <TextWithIcon
                  icon={CommonIcon.map}
                  text={getCityName(selected_data?.applicant)}
                />
                <hr />
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Table className={"t-a-Left"}>
                      <GetViewForDetails
                        color={CommonColor.secondary_color}
                        name={CommonStringForApplicant.added_by}
                        value={selected_data?.applicant?.added_by}
                      />
                      <GetViewForDetails
                        name={CommonStringForJob.expected_payrate}
                        value={
                          selected_data?.applicant?.payrate === ""
                            ? selected_data?.applicant?.payrate
                            : "$" +
                              selected_data?.applicant?.payrate?.replace(
                                "$",
                                ""
                              )
                        }
                      />
                      <GetViewForDetails
                        name={CommonStringForApplicant.work_authority}
                        value={selected_data?.applicant?.work_authority ?? ""}
                      />
                      <GetViewForDetails
                        name={CommonStringForApplicant.tax_term}
                        value={selected_data?.applicant?.tax_term}
                      />
                      <GetViewForDetails
                        name={CommonStringForApplicant.experience}
                        value={selected_data?.applicant?.experience}
                      />
                      <GetViewForDetails
                        name={CommonStringForApplicant.source_by}
                        value={
                          selected_data?.applicant?.source_by ?? CommonString.na
                        }
                      />
                    </Table>
                  </Grid>
                </Grid>
                <hr />
                <RowTextHtml
                  title={CommonStringForApplicant.comment}
                  value={selected_data?.applicant?.comment}
                />
                <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                  {selected_data?.comments?.length === 0 ||
                  selected_data?.comments === undefined ? (
                    <></>
                  ) : (
                    <Table>
                      <TableRow>
                        <TableTh
                          style={{ position: "relative", maxWidth: "auto" }}
                          align="center"
                        >
                          Status
                        </TableTh>
                        <TableTh
                          style={{ position: "relative" }}
                          align="center"
                        >
                          Comment
                        </TableTh>
                      </TableRow>
                      {selected_data?.comments?.map((data) => (
                        <>
                          <TableRow>
                            <TableTd>{data.status}</TableTd>
                            <TableTd
                              style={{ maxWidth: "auto", whiteSpace: "normal" }}
                            >
                              {data.comment !== ""
                                ? data.comment
                                : CommonString.na}
                            </TableTd>
                          </TableRow>
                        </>
                      ))}
                    </Table>
                  )}
                </div>
                <ViewResumeTExtContainer>
                  <ViewResumeText onClick={handleOpen}>
                    <Icon icon={CommonIcon.link} />
                    <span style={{ marginLeft: 10 }}>View Resume</span>
                  </ViewResumeText>
                </ViewResumeTExtContainer>
                {offer_details !== null && offer_details !== undefined ? (
                  <>
                    <CustomSubTitle
                      title={CommonString.offer}
                      titleSpan={CommonString.details}
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.offer_date}
                      value={moment(offer_details?.offer_date).format(
                        DATE_FORMATE
                      )}
                    />
                    {!onCheckLevelAccessibility() ? (
                      <></>
                    ) : selected_data?.status ===
                      SUBMISSION_STATUS.PLACEMENT ? (
                      <RowText
                        title={CommonStringForInterviewDetails.start_date}
                        value={
                          offer_details?.start_date === ""
                            ? ""
                            : moment(offer_details?.start_date).format(
                                DATE_FORMATE
                              )
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <RowText
                      title={CommonStringForInterviewDetails.payrate}
                      value={offer_details?.payrate}
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.billrate}
                      value={"$" + offer_details?.billrate}
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.conversion_1099}
                      value={offer_details?.conversion_1099}
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.margin}
                      value={offer_details?.prexel_share}
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.comment}
                      value={offer_details?.comment}
                    />
                  </>
                ) : (
                  <div />
                )}
                {project_details !== null ? (
                  <>
                    <TitleWithIcon>
                      <CustomSubTitle
                        title={CommonString.project_detail}
                        titleSpan={CommonString.details}
                      />
                      {/* <Icon
                        onClick={() => {
                          setisOpenModal(true);
                          setmodalStatus(SUBMISSION_STATUS.PROJECT_COMPLETED);
                          dispatch(changeIsUpdate(true));
                        }}
                        icon="uil:edit"
                      /> */}
                    </TitleWithIcon>
                    <RowText
                      title={CommonStringForProjectDetails.end_date}
                      value={
                        project_details.project_end_date !== undefined
                          ? moment(project_details.project_end_date).format(
                              DATE_FORMATE
                            )
                          : ""
                      }
                    />
                    <RowText
                      title={CommonStringForProjectDetails.note}
                      value={project_details ? project_details.note : ""}
                    />
                  </>
                ) : (
                  <div />
                )}
                {selected_data?.interviewData?.data !== null ? (
                  <>
                    <TitleWithIcon>
                      <CustomSubTitle
                        title={CommonString.interview}
                        titleSpan={CommonString.details}
                      />
                      <Icon
                        onClick={() => {
                          setisOpenModal(true);
                          setmodalStatus(SUBMISSION_STATUS.INTERVIEW);
                          dispatch(changeIsUpdate(true));
                          dispatch(changeInterviewDetails(interview_details));
                        }}
                        icon="uil:edit"
                      />
                    </TitleWithIcon>
                    <RowText
                      title={CommonStringForInterviewDetails.date}
                      value={
                        interview_details
                          ? moment(interview_details.date).format(DATE_FORMATE)
                          : ""
                      }
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.interview_mode}
                      value={
                        interview_details
                          ? interview_details.interview_mode
                          : ""
                      }
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.note}
                      value={interview_details ? interview_details.note : ""}
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.point_of_contact}
                      value={
                        interview_details
                          ? interview_details.point_of_contact
                          : ""
                      }
                    />
                    <RowText
                      title={CommonStringForInterviewDetails.time}
                      value={
                        interview_details
                          ? interview_details?.interview_time !== null
                            ? `${convertTo12HourFormat(
                                interview_details?.interview_time
                              )} ${interview_details?.time_zone}`
                            : interview_details?.time
                          : ""
                      }
                    />
                    <RowText
                      title={getFieldNameByInterviewModeInInterview(
                        interview_details?.interview_mode
                      )}
                      value={
                        interview_details ? interview_details.web_ex_link : ""
                      }
                    />
                  </>
                ) : (
                  <div />
                )}
              </>
            ) : (
              <CustomPDFViewer
                handleClose={handleClose}
                resumeLink={
                  selected_data?.applicant
                    ? selected_data?.applicant.resume_link
                    : ""
                }
              />
            )}
          </Grid>
        </Grid>
      </>
    </>
  );
}
