import React from "react";
import ButtonGroup from "../CommonComponet/ButtonGroup";
import { CommonString, CommonTitle } from "../../Utils/CommonString";
import {
  ConfirmationFormTitle,
  DeleteConfirmationFormTitle,
  ModalIconContainer,
  ModalTitle,
  ModalTitleSpan,
} from "../../style/common.style";
import CustomTooltip from "../CommonComponet/Tooltip";
import { CommonIcon } from "../../Utils/CommonIcon";
import { ConfirmationAlertContainer } from "../../style/login.style";
import { ModalIcon } from "../../style/Icon.style";

export default function ConfirmationAlertForAddPartner(props) {
  return (
    <ConfirmationAlertContainer>
      <DeleteConfirmationFormTitle>
        <ModalTitle>
          Do you want to <ModalTitleSpan>add partner?</ModalTitleSpan>
        </ModalTitle>
        <ModalIconContainer>
          <CustomTooltip
            placement={CommonString.top_placement}
            title={CommonTitle.close}
            name={
              <ModalIcon
                icon={CommonIcon.modal_close}
                onClick={props.onClose}
              />
            }
          />
        </ModalIconContainer>
      </DeleteConfirmationFormTitle>
      <ConfirmationFormTitle>
        This client position id is already exist.
      </ConfirmationFormTitle>
      <ButtonGroup
        solidBtnClick={() => props.onDelete()}
        outlinedBtnClick={props.onClose}
        solidBtnName={"Add Partner"}
        outlinedBtnName={"Close"}
      />
    </ConfirmationAlertContainer>
  );
}
