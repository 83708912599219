import { Icon } from "@iconify/react";
import React from "react";
import { CommonIcon } from "../../Utils/CommonIcon";
import { Grid } from "@material-ui/core";

export default function ApplicantProfileCard() {
  function TextWithIcon(props) {
    return (
      <Grid
        item
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          fontSize: 12,
          color: "grey",
        }}
      >
        <Icon icon={props.icon} />
        <p>{props.text}</p>
      </Grid>
    );
  }
  function ExperienceWithSkill(props) {
    return (
      <Grid
        item
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          fontSize: 12,
          color: "grey",
          justifyContent: "space-between",
        }}
      ></Grid>
    );
  }
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <TextWithIcon icon={CommonIcon.map} text={"Birnubgham,AL,US"} />
        <TextWithIcon icon={CommonIcon.calender} text={"18 years ago"} />
        <TextWithIcon icon={CommonIcon.glob} text={"US Citizen"} />
        <TextWithIcon
          icon={CommonIcon.road_map}
          text={"Not willing to relocated"}
        />
        <TextWithIcon icon={CommonIcon.cash} text={"135,000+ (USD) / yr"} />
        <TextWithIcon icon={CommonIcon.work_from_home} text={"No preference"} />
        <TextWithIcon
          icon={CommonIcon.clock}
          text={
            <>
              "Updated: "<b>{"6 moths ago"}</b>
            </>
          }
        />
      </Grid>
      <Grid container>
        <ExperienceWithSkill skill={"Cloude"} years={"2019"} experience={"10"} />
      </Grid>
    </>
  );
}
