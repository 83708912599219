import React, { useState, useEffect } from "react";
import FormInput from "../CommonComponet/FormInput";
import CustomButton from "../CommonComponet/Button";
import { useSelector } from "react-redux";
import { FormButton } from "../../style/button.style";

export default function AddLevel(props) {
  const [level, setLevel] = useState();
  const [designation, setDesignation] = useState();
  const [ERROR_NAME, setERROR_NAME] = useState();
  const modal_status = useSelector(
    (state) => state.LevelStoreRedux.modal_status
  );

  useEffect(() => {
    onPrefilledData();
  }, []);

  function onPrefilledData() {
    if (props.isUpdate && props.data != null) {
      setLevel(props.data.name);
      setDesignation(props.data.designation);
    }
  }

  function onAddLevel() {
    if (!validate()) {
      return;
    }
    var data = {
      name: level,
      designation: designation,
      value: level ? parseInt(level.replace(/[A-Za-z$-]/g, "")) : " ",
    };
    props.onSubmitData(modal_status, data);
    return;
  }

  function validate() {
    if (level === null || level === undefined) {
      setERROR_NAME("LEVEL");
      return false;
    }
    if (designation === null || designation === undefined) {
      setERROR_NAME("DESIGNATION");
      return false;
    }
    setERROR_NAME("");
    return true;
  }

  return (
    <>
      <FormInput
        error={ERROR_NAME === "LEVEL"}
        value={level || ""}
        onChange={(e) => {
          setERROR_NAME("");
          setLevel(e.target.value);
        }}
        placeholder="Level"
      />
      <FormInput
        error={ERROR_NAME === "DESIGNATION"}
        value={designation || ""}
        onChange={(e) => {
          setDesignation(e.target.value);
          setERROR_NAME("");
        }}
        placeholder="Designation"
      />
      <FormButton>
        <CustomButton
          onClick={onAddLevel}
          name={props.isUpdate ? "Update" : "Add"}
        />
      </FormButton>
    </>
  );
}
