import ApiUtils from "../APICallServices/APIUtils";

export default class ApplicantProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  getApplicantList = (page = 1) => {
    return this.Apiprovider.init().get(`applicant/get-applicant?page=${page}`);
  };
  addNewApplicant = (data) => {
    return this.Apiprovider.init().post("applicant/add-applicant", data);
  };
  updateApplicant = (data) => {
    return this.Apiprovider.init().post("applicant/update-applicant", data);
  };
  deleteApplicant = (data) => {
    return this.Apiprovider.init().post("applicant/delete-applicant", data);
  };

  getSingleApplicant = (data) => {
    return this.Apiprovider.init().post("applicant/get-single-applicant", data);
  };
  searchApplicant = (data, page = 1) => {
    return this.Apiprovider.init().post(
      `applicant/search-applicant?page=${page}`,
      data
    );
  };
  checkApplicantExist = (data) => {
    return this.Apiprovider.init().post(
      "applicant/check-applicant-exist",
      data
    );
  };
}
