import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import { OutlinedBtn, SolidBtn } from "./common.style";
import { CommonString } from "../Utils/CommonString";
import OutlinedButton from "../component/CommonComponet/OutlinedButton";

const AddButtonForMobile = styled(OutlinedBtn)`
  display: none !important;
  @media ${CommonString.mobile} {
    // min-width: 15px !important;
    width: auto !important;
    padding: 0px 4px !important;
    display: block !important;
    // margin-top: 5px !important;
    background-color: ${(props) =>
      props.isApplicant
        ? CommonColor.secondary_color
        : CommonColor.white}!important;
    color: ${(props) =>
      props.isApplicant
        ? CommonColor.white
        : CommonColor.primary_color} !important;
    border: 0.5px solid
      ${(props) =>
        props.isApplicant
          ? CommonColor.secondary_bg
          : CommonColor.primary_color} !important;
    font-weight: ${(props) => (props.isApplicant ? "bold" : "inherits")};
  }
`;
const ButtonForChangePageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
  margin-top: -15px;
`;
const ButtonForChangePage = styled(SolidBtn)`
  /* transition: 0.4s !important;
  text-transform: capitalize !important;
  display: flex;
  align-items: center;
  white-space: nowrap; */
  // margin-top: 10px !important;
  margin: 0px !important;
  font-size: 11px !important;
  /* line-height: 0.75 !important; */
  border-radius: 50px !important;
  background-color: ${CommonColor.button_hover} !important;
  font-weight: ${(props) => (props.isApplicant ? "bold" : "inherits")};
  @media ${CommonString.mobile} {
    display: none !important;
  }
`;
const AddButton = styled(SolidBtn)`
  transition: 0.4s !important;
  text-transform: capitalize !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
  // margin-top: 10px !important;
  margin: 0px !important;
  margin-right: ${(props) => (props.marginRight ? "5px" : 0)} !important;
  font-size: 11px !important;
  line-height: 0.75 !important;
  font-weight: ${(props) => (props.isApplicant ? "bold" : "inherits")};
  @media ${CommonString.mobile} {
    display: none !important;
  }
`;
const FormButton = styled.div`
  margin-top: 15px;
`;
const POCButton = styled(OutlinedButton)`
  margin-top: 10px !important;
  margin-left: 0px !important;
`;
const AddButtonForApplicant = styled(OutlinedBtn)`
  transition: 0.4s !important;
  padding: 2px 0px !important;
  width: 150px !important;
  background-color: ${CommonColor.secondary_color}!important;
  color: ${CommonColor.white} !important;
  border: 0.5px solid ${CommonColor.secondary_bg} !important;
  text-transform: capitalize !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
  // margin-top: 10px !important;
  margin: 0px !important;
  font-size: 11px !important;
  font-weight: bold;
  height: fit-content;
  // min-width: 150px !important;
  padding: 5px !important;
  font-size: 15px !important;
  @media ${CommonString.mobile} {
    display: none !important;
  }
`;
export {
  ButtonForChangePageContainer,
  ButtonForChangePage,
  AddButtonForMobile,
  AddButton,
  FormButton,
  POCButton,
  AddButtonForApplicant,
};
