import React from "react";
import VerticalMenu from "../../CommonComponet/VerticalMenu";
import { UPDATE_LEVEL, VIEW_LEVEL } from "../../../Utils/enum";
import {
  CustomTableContainer,
  Table,
  TableTbody,
  TableRow,
  TableTh,
  TableTd,
} from "../../../style/table.styled";
import { LEVEL_TH } from "../../../Utils/constant";
import Container from "../../CommonComponet/Container";
import LevelController from "../../../controller/LevelController";
import { getVerticalMenu } from "../../../Utils/utilitiFunction";

export default function LevelTable(props) {
  var levelController = new LevelController();
  function enableButton(e) {
    if (e.disabled) {
      window.setTimeout(enable(), 200);
    }
  }
  function enable(e) {
    e.disabled = false;
  }

  return (
    <CustomTableContainer>
      <Table>
        <TableTbody>
          <TableRow>
            {LEVEL_TH.map((singleTH, index) => (
              <TableTh align={singleTH.align} key={index}>
                {singleTH.name}
              </TableTh>
            ))}
          </TableRow>
          {props.levelList.length === 0 ? (
            <></>
          ) : (
            props.levelList.map((data, index) => {
              return (
                <TableRow
                  key={index}
                  onDoubleClick={() =>
                    levelController.onViewModal(VIEW_LEVEL, data)
                  }
                  onClick={() =>
                    window.innerWidth < 500
                      ? levelController.onViewModal(VIEW_LEVEL, data)
                      : null
                  }
                >
                  <TableTd>{index + 1}</TableTd>
                  <TableTd onClick={(e) => enableButton(e)} key={data.name}>
                    {data.name}
                  </TableTd>
                  <TableTd>{data.designation}</TableTd>
                  <TableTd>
                    <VerticalMenu
                      data={getVerticalMenu()}
                      onView={() =>
                        levelController.onViewModal(VIEW_LEVEL, data)
                      }
                      onEdit={() =>
                        levelController.onViewModal(UPDATE_LEVEL, data)
                      }
                      onDelete={() => props.onDelete(data)}
                    />
                  </TableTd>
                </TableRow>
              );
            })
          )}
        </TableTbody>
      </Table>
      <Container
        isLoading={props.isLoading === true}
        isVisible={props.isLoading === false && props.levelList.length === 0}
      />
    </CustomTableContainer>
  );
}
