import { useDispatch } from "react-redux";
import { getRecruiterData } from "../feature/store/ReduxStore";
import RecruiterProvider from "../services/provider/RecruiterProvider";

export default class RecruiterController {
  constructor() {
    this.recruiterProvider = new RecruiterProvider();
    this.dispatch = new useDispatch();
  }
  addToken = async (data) => {
    var res = await this.recruiterProvider.addToken(data);
    return res.data.data;
  };
  getRecruiters = async () => {
    var res = await this.recruiterProvider.getRecruiterList();
    return res.data.data;
  };
  deleteRecruiter = async (data) => {
    var res = await this.recruiterProvider.deleteRecruiter(data);
    return res;
  };
  addRecruiter = async (data) => {
    var res = await this.recruiterProvider.addNewRecruiter(data);
    return res;
  };
  updateRecruiter = async (data) => {
    var res = await this.recruiterProvider.updateRecruiter(data);
    return res;
  };
  UploadRecruiterProfile = async (data) => {
    var res = await this.recruiterProvider.UploadRecruiterProfile(data);
    return res;
  };
  getOfficeTime = async () => {
    var res = await this.recruiterProvider.getOfficeTime();
    return res.data.data;
  };
  getRecruiterData = async () => {
    var res = await this.recruiterProvider.getRecruiterList();
    this.dispatch(getRecruiterData(res.data.data));
    // return res;
  };
  getAccessById = async (data) => {
    var res = await this.recruiterProvider.getAccessDataById(data);
    return res.data.data;
  };
  updateAccessById = async (data) => {
    var res = await this.recruiterProvider.updateAccessDataById(data);
    return res.data.data;
  };
}
