import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider as AlertProvider } from "react-alert";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { CustomIcon } from "./style/Icon.style";
import { CommonString } from "./Utils/CommonString";
import { CommonColor } from "./Utils/color";
import { CommonIcon } from "./Utils/CommonIcon";
import { AlertContainer } from "./style/menu.style";
const loader = document.querySelector(".loader");
const showLoader = () => loader.classList.remove("loader--hide");
const hideLoader = () => loader.classList.add("loader--hide");

const AlertTemplate = ({ style, options, message, close }) => (
  <AlertContainer
    background={
      options.type === CommonString.success
        ? CommonColor.bg_green
        : options.type === CommonString.info
        ? CommonColor.bg_blue
        : CommonColor.bg_red
    }
    color={
      options.type === CommonString.success
        ? CommonColor.green
        : options.type === CommonString.info
        ? CommonColor.blue
        : CommonColor.red
    }
  >
    {options.type === CommonString.info && CommonString.blank_text}
    {options.type === CommonString.success && CommonString.blank_text}
    {options.type === CommonString.error && CommonString.blank_text}
    {message}
    <CustomIcon onClick={close} icon={CommonIcon.close_notification} />
  </AlertContainer>
);
const options = {
  timeout: 5000,
  type: CommonString.success,
};

setTimeout(
  () =>
    ReactDOM.render(
      <BrowserRouter>
        <AlertProvider
          position={CommonString.top_right}
          template={AlertTemplate}
          {...options}
        >
          <App hideLoader={hideLoader} showLoader={showLoader} />
        </AlertProvider>
      </BrowserRouter>,
      document.getElementById(CommonString.root)
    ),
  1000
);
reportWebVitals();
