import React, { useState } from "react";
import {
  ButtonContainer,
  DiceMainContainer,
  InputContainer,
  SearchContainer,
  SearchInfoMessage,
  SearchTitle,
} from "../../style/dice.style";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";
import AddLocationComponent from "./AddLocationComponent";
import { onClearAllLocation } from "../../feature/store/DiceStore";
import { useDispatch, useSelector } from "react-redux";
import ApplicantProfileCard from "./ApplicantProfileCard";

export default function DiceScreen() {
  const dispatch = useDispatch();

  const [jobDescription, setJobDescription] = useState("");
  const [keyword, setKeyword] = useState("");

  const searchedLocation = useSelector(
    (state) => state.DiceStore.searchedLocation
  );

  function onClearAll() {
    setJobDescription("");
    setKeyword("");
    dispatch(onClearAllLocation([]));
  }
  function onSearch() {
    var sendData = {
      jobDescription: jobDescription,
      keyword: keyword,
      location: searchedLocation,
    };
    console.log(sendData);
  }
  return (
    <DiceMainContainer>
      <SearchContainer>
        <SearchTitle>Candidate Search</SearchTitle>
        <InputContainer>
          <div>
            <Icon icon={CommonIcon.search} />
            <input
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              placeholder="IntelliSearch copy and paste job description or resume (optional)"
            />
          </div>
        </InputContainer>
        <SearchInfoMessage>
          Minimum of 150 characters (max 20,000), For searches with less 150,
          use the "keyword or Boolean Search" filed below.
        </SearchInfoMessage>
        <InputContainer>
          <div>
            <Icon icon={CommonIcon.search} />
            <input
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Keyword or Boolean Search"
            />
          </div>
        </InputContainer>
        <AddLocationComponent />
        <ButtonContainer>
          <p onClick={() => onClearAll()}>Clear All</p>
          <p>New Search</p>
          <button onClick={() => onSearch()}>Search</button>
        </ButtonContainer>
      </SearchContainer>
      <ApplicantProfileCard />
    </DiceMainContainer>
  );
}
