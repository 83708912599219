import { Icon } from "@iconify/react";
import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import { CommonString } from "../Utils/CommonString";

const ResumeContaienr = styled.div`
  padding: 10px 20px;
  text-align: left;
  cursor: pointer;
`;

const ResumeText = styled.div`
  text-decoration: underline;
  color: ${CommonColor.secondary_color};
`;
const RecruiterImgContainer = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-between;
`;
const RecruiterImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
const RecruiterTextContainer = styled.div`
  margin-left: 20px;
`;
const RecruiterText = styled.p`
  color: ${CommonColor.secondary_color};
  font-size: 13px;
`;
const RecruiterBigText = styled.a`
  color: ${CommonColor.primary_color} !important;
  font-size: 13px !important;
  // margin-top: 5px;
  cursor: pointer;
  text-decoration: none;
  &:hover svg {
    color: ${CommonColor.grey};
  }
  @media ${CommonString.mobile} {
    display: block;
  }
`;
const RecruiterTitle = styled.h5`
  font-weight: 800;
`;
const RecruiterSpan = styled.p`
  font-size: 11px;
  color: ${CommonColor.grey};
  display: flex;
  align-items: center;
`;
const RecruiterIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 14px;
  color: ${CommonColor.light_text};
`;
const RecruiterSocialIcon = styled(Icon)`
  font-size: 18px;
  margin-right: 10px;
  color: ${CommonColor.light_text};
  margin-left: ${(props) => (props.isMarginLeft ? "10px" : "0")};
  @media ${CommonString.mobile} {
    margin-left: 0;
  }
`;
const RecruiterReportText = styled.h6`
  // font-weight: bold;
  color: ${CommonColor.primary_color};
`;
const RecruiterReportSpan = styled.span`
  color: ${CommonColor.secondary_color};
  margin: 0 10px;
`;
const RecruiterReportContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content: space-between;
  margin-left: -10px;
  margin-top: 10px;
`;
const FlexContainerForSocialLinkForRecruiter = styled.div`
  display: flex;
  align-items: center;
  @media ${CommonString.mobile} {
    display: block;
  }
`;
const RecruiterBigTextContainer = styled.div`
  max-height: 100px;
  overflow: auto;
`;
export {
  RecruiterBigTextContainer,
  FlexContainerForSocialLinkForRecruiter,
  RecruiterReportContainer,
  RecruiterReportSpan,
  RecruiterReportText,
  RecruiterBigText,
  RecruiterSocialIcon,
  RecruiterIcon,
  RecruiterSpan,
  RecruiterTitle,
  ResumeContaienr,
  ResumeText,
  RecruiterImgContainer,
  RecruiterImg,
  RecruiterTextContainer,
  RecruiterText,
};
