import React, { useState, useEffect } from "react";
import TableToolbar from "./TableToolbar";
import AddRecruiter from "../Modal/AddRecruiter";
import BaseModal from "../Modal/BaseModal";
import {
  RECRUITERS,
  ADD_RECRUITERS,
  UPDATE_RECRUITERS,
  VIEW_RECRUITERS,
  RECRUITER,
} from "../../Utils/enum";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteConfirmation from "../Modal/DeleteConfirmation";
import { useAlert } from "react-alert";
import RecruiterViewDetails from "../ViewDetails/RecruiterViewDetails";
import {
  ADD_RECRUITER_ERROR,
  ADD_RECRUITER_SUCCESS,
  DELETE_RECRUITER_ERROR,
  DELETE_RECRUITER_SUCCESS,
  UPDATE_RECRUITER_ERROR,
  UPDATE_RECRUITER_SUCCESS,
} from "../../Utils/alertMessage";
import RecruiterController from "../../controller/RecruiterController";
import RecruiterTable from "./Table/RecruiterTable";
import { recruiterModalStatus } from "../../Utils/utilitiFunction";
import { useDispatch, useSelector } from "react-redux";
import { CommonString } from "../../Utils/CommonString";
import { TableMainContainer } from "../../style/table.styled";
import { changeInitialLoader } from "../../feature/store/ReduxStore";

export default function RecruiterDataTable(props) {
  const dispatch = useDispatch();
  var recruiterController = new RecruiterController();
  const trecruiterList = useSelector((state) => state.counter.recruiterData);
  const access_data = useSelector((state) => state.counter.access_data);
  const alert = useAlert();
  const [selectedData, setselectedData] = useState(null);
  const [isopenModal, setisOpenModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(ADD_RECRUITERS);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getData();
  });

  function onViewModal(modalSt, data) {
    setisOpenModal(true);
    setModalStatus(modalSt);
    setselectedData(data);
  }

  function getData() {
    if (props.searchText != null && props.searchText.length > 0) {
      return props.searchResult;
    }
    return trecruiterList;
  }

  function onDelete(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            isLoading={isLoading}
            name={data.name}
            onClose={onClose}
            onDelete={() => {
              OndeleteData(data);
              onClose();
              setisOpenModal(false);
            }}
          />
        );
      },
    });
  }

  async function OndeleteData(data) {
    dispatch(changeInitialLoader(true));
    var sendData = {
      _id: data._id,
    };
    var res = await recruiterController.deleteRecruiter(sendData);
    if (res.data.status === 200) {
      dispatch(changeInitialLoader(false));
      props.onRefresh();
      alert.show(DELETE_RECRUITER_SUCCESS, { type: CommonString.success });
    } else {
      alert.show(DELETE_RECRUITER_ERROR, { type: CommonString.error });
      dispatch(changeInitialLoader(false));
    }
  }

  async function onSubmitData(data) {
    dispatch(changeInitialLoader(true));
    if (modalStatus === UPDATE_RECRUITERS) {
      var sendData = {
        _id: selectedData._id,
        ...data,
      };
      var res = await recruiterController.updateRecruiter(sendData);
      if (res.data.status === 200) {
        dispatch(changeInitialLoader(false));
        props.onRefresh();
        setisOpenModal(!isopenModal);
        alert.show(UPDATE_RECRUITER_SUCCESS, { type: CommonString.success });
        let res = await recruiterController.updateAccessById({
          _id: data.access_id,
          user_id: selectedData._id,
          ...access_data,
        });
      } else {
        dispatch(changeInitialLoader(false));
        alert.show(UPDATE_RECRUITER_ERROR, { type: CommonString.error });
      }
      return;
    }
    var add_res = await recruiterController.addRecruiter(data);
    if (add_res.data.status === 200) {
      dispatch(changeInitialLoader(false));
      props.onRefresh();
      setisOpenModal(!isopenModal);
      alert.show(ADD_RECRUITER_SUCCESS, { type: CommonString.success });
    } else {
      dispatch(changeInitialLoader(false));
      alert.show(ADD_RECRUITER_ERROR, { type: CommonString.error });
    }
  }

  return (
    <>
      <TableToolbar
        isLoading={props.isLoading ? true : false}
        title={RECRUITERS}
        isVisible={true}
        buttonName={RECRUITER}
        onClick={() => onViewModal(ADD_RECRUITERS, null)}
      />
      <BaseModal
        isRecruiter={true}
        isUpdate={modalStatus === UPDATE_RECRUITERS}
        isLoading={isLoading}
        isView={modalStatus === VIEW_RECRUITERS ? true : false}
        editClose={() => onViewModal(UPDATE_RECRUITERS, selectedData)}
        deleteClose={() => onDelete(selectedData)}
        open={isopenModal}
        mystyle={{
          height: modalStatus === VIEW_RECRUITERS ? "auto" : "100vh",
          width:
            modalStatus === VIEW_RECRUITERS
              ? window.innerWidth > 500
                ? "40%"
                : "90%"
              : window.innerWidth > 500
              ? "100%"
              : "90%",
        }}
        formTitle={recruiterModalStatus(modalStatus, selectedData)}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        {modalStatus === VIEW_RECRUITERS ? (
          <RecruiterViewDetails data={selectedData} />
        ) : (
          <AddRecruiter
            isUpdate={modalStatus === UPDATE_RECRUITERS}
            data={selectedData}
            onSubmitData={onSubmitData}
          />
        )}
      </BaseModal>
      <TableMainContainer>
        <RecruiterTable
          isLoading={props.isLoading}
          onDelete={onDelete}
          onViewModal={onViewModal}
          recruiterList={getData()}
        />
      </TableMainContainer>
    </>
  );
}
