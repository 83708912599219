import { createSlice } from "@reduxjs/toolkit";
import { CommonStringForLocalStorage } from "../../Utils/CommonString";

export const ReduxStore = createSlice({
  name: "counter",
  initialState: {
    is_open: false,
    is_applicant: false,
    value: 0,
    job_count: 0,
    is_active: true,
    is_visible: true,
    is_visible_for_admin: true,
    resumeLink: "",
    jobData: [],
    applicantData: [],
    applicantSearchData: [],
    applicantPagenumber: 1,
    applicantSearchPagenumber: 0,
    jobPagenumber: 1,
    searchJobPagenumber: 1,
    submissionData: [],
    submissionPagenumber: 1,
    partnerData: [],
    clientData: [],
    recruiterData: [],
    onlyRecruiterData: [],
    levelData: [],
    skillData: [],
    notificationData: [],
    dashBoardData: {},
    userName: "",
    alert_message_type: "",
    menuStatus:
      localStorage.getItem(CommonStringForLocalStorage.view_status) === null
        ? CommonStringForLocalStorage.dashboard
        : localStorage.getItem(CommonStringForLocalStorage.view_status),
    searchText: "",
    search_loading: false,
    user_detail: null,
    selected_report_type: "Year",
    isChangeResumeInApplicant: false,
    isLoadingInModal: false,
    initial_loader: false,
    refresh_status: "",
    filterStatusObj: {
      status: "ALL",
      recruiter_id: "123",
      client_id: "123",
      partner_id: "123",
    },
    access_data: [
      {
        job_create: false,
        job_update: false,
        job_delete: false,
        applicant_create: false,
        applicant_update: false,
        applicant_delete: false,
        client_create: false,
        client_update: false,
        client_delete: false,
        review_create: false,
        review_update: false,
        review_delete: false,
        submission_create: false,
        submission_update: false,
        submission_delete: false,
        offer_create: false,
        offer_update: false,
        offer_delete: false,
        placement_create: false,
        placement_update: false,
        placement_delete: false,
        job_status_update: false,
        add_partner_in_job: false,
        close_applicant: false,
      },
    ],
    daily_report_data: [],
    access_data_by_user_id: [],
    user_data: [],
  },
  reducers: {
    onChangeUserData: (state, action) => {
      state.user_data = action.payload;
    },
    onChangeTotalJobCount: (state, action) => {
      state.job_count = action.payload;
    },
    onChangeAccessDataById: (state, action) => {
      state.access_data_by_user_id = action.payload;
    },
    onChangeAccessData: (state, action) => {
      state.access_data = action.payload;
    },
    onRemoveSubmissionData: (state, action) => {
      state.submissionData = action.payload;
    },
    onUpdateStatus: (state, action) => {
      var data = state.submissionData.map(function (item) {
        return item._id === action.payload._id ? action.payload : item;
      });
      state.submissionData = data;
    },
    changeInitialLoader: (state, action) => {
      state.initial_loader = action.payload;
    },
    changeIsLoadingInModal: (state, action) => {
      state.isLoadingInModal = action.payload;
    },
    changeIsChangeResumeInApplicant: (state, action) => {
      state.isChangeResumeInApplicant = action.payload;
    },
    changeSelectedReportType: (state, action) => {
      state.selected_report_type = action.payload;
    },
    changeUserDetail: (state, action) => {
      state.user_detail = action.payload;
    },
    changeSearchLoading: (state, action) => {
      state.search_loading = action.payload;
    },
    changeSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    deleteSubmission: (state, action) => {
      const newList = state.submissionData.filter(
        (item) => item._id !== action.payload
      );

      state.submissionData = newList;
    },
    onChangePageNumber: (state, action) => {
      state.jobPagenumber = action.payload;
    },
    getjobData: (state, action) => {
      if (action.payload === undefined) return;
      if (action.payload.length > 0) {
        state.jobPagenumber = state.jobPagenumber + 1;
      }
      state.jobData.push(...action.payload);
    },
    removeJObData: (state, action) => {
      state.jobData = action.payload;
    },
    onChangeJobPageNumber: (state, action) => {
      state.searchJobPagenumber = action.payload;
    },
    searchJob: (state, action) => {
      if (action.payload.length > 0) {
        state.searchJobPagenumber = state.searchJobPagenumber + 1;
      }
      state.jobData.push(...action.payload);
    },
    addJobData: (state, action) => {
      state.jobData.unshift(...action.payload);
    },
    deletejob: (state, action) => {
      const newList = state.jobData.filter(
        (item) => item._id !== action.payload
      );
      state.jobData = newList;
    },
    updateJobData: (state, action) => {
      var data = state.jobData.map(function (item) {
        return item._id === action.payload._id ? action.payload : item;
      });
      state.jobData = data;
    },
    getApplicantData: (state, action) => {
      if (action.payload.length > 0) {
        state.applicantPagenumber = state.applicantPagenumber + 1;
      }
      state.applicantData.push(...action.payload);
    },

    getSearchApplicantData: (state, action) => {
      if (action.payload.res.length > 0) {
        state.applicantSearchPagenumber = action.payload.pageNumber;
      }
      if (action.payload.pageNumber == 1) {
        state.applicantSearchData = action.payload.res;
      } else {
        state.applicantSearchData.push(...action.payload.res);
      }
    },
    removeSearchApplicantData: (state, action) => {
      state.applicantSearchData = action.payload;
    },
    onChangeApplicantPagenumber: (state, action) => {
      state.applicantPagenumber = action.payload;
    },
    addSingleApplicantData: (state, action) => {
      state.applicantData.unshift(action.payload);
    },
    updateApplicantData: (state, action) => {
      var data = state.applicantData.map(function (item) {
        return item._id === action.payload._id ? action.payload : item;
      });

      state.applicantData = data;
    },
    updatePartnerData: (state, action) => {
      var data = state.partnerData.map(function (item) {
        return item._id === action.payload._id ? action.payload : item;
      });

      state.partnerData = data;
    },
    changeJobStatus: (state, action) => {
      var data = state.jobData.map(function (item) {
        return item.child_job._id === action.payload._id
          ? action.payload
          : item;
      });
      state.jobData = data;
    },
    deleteApplicant: (state, action) => {
      const newList = state.applicantData.filter(
        (item) => item._id !== action.payload
      );

      state.applicantData = newList;
    },
    getInitialSubmissionData: (state, action) => {
      state.submissionData = action.payload;
    },
    onChangeSubmissionPageNumber: (state, action) => {
      state.submissionPagenumber = action.payload;
    },
    getsubmissionData: (state, action) => {
      // if (action.payload.length > 0) {
      //   state.submissionPagenumber = state.submissionPagenumber + 1;
      // }
      state.submissionData.push(...action.payload);
      state.submissionData = [
        ...new Map(
          state.submissionData.map((item) => [item._id, item])
        ).values(),
      ];
    },
    updateSubmissionData: (state, action) => {
      var tempData = [];
      state.submissionData.map(function (item) {
        if (item._id === action.payload._id) {
          item.call_1_status = action.payload.call_1_status;
          item.call_2_status = action.payload.call_2_status;
          item.call_3_status = action.payload.call_3_status;
        }
        tempData.push(item);
      });

      state.submissionData = tempData;
    },
    updateSubmissionTracker: (state, action) => {
      var data = state.submissionData.map(function (item) {
        return item._id === action.payload._id ? action.payload : item;
      });
      state.submissionData = data;
    },
    getNotificationData: (state, action) => {
      state.notificationData = action.payload;
    },
    getPartnerData: (state, action) => {
      state.partnerData = action.payload;
    },

    getClientData: (state, action) => {
      state.clientData = action.payload;
    },
    getRecruiterData: (state, action) => {
      state.recruiterData = action.payload;
      state.onlyRecruiterData = action.payload.filter(
        (data) => data.is_recruiter === "true"
      );
    },
    getLevelData: (state, action) => {
      state.levelData = action.payload;
    },
    getSkillData: (state, action) => {
      state.skillData = action.payload;
    },
    getVisibility: (state, action) => {
      state.is_visible = action.payload;
    },
    getVisibilityForAdmin: (state, action) => {
      state.is_visible_for_admin = action.payload;
    },
    addSubmissionData: (state, action) => {
      state.submissionData.push(action.payload);
      var newData = state.submissionData.filter(
        (item, index) => state.submissionData.indexOf(item) === index
      );
      state.submissionData = newData;
    },
    changeModalStatus: (state, action) => {
      state.is_open = action.payload;
    },
    changeUserName: (state, action) => {
      state.userName = action.payload;
    },
    getApplicantStatus: (state, action) => {
      state.is_applicant = action.payload;
    },
    changeResumeLink: (state, action) => {
      state.resumeLink = action.payload;
    },
    getDashBoardData: (state, action) => {
      state.dashBoardData = action.payload;
    },
    changeAlertMessageType: (state, action) => {
      state.alert_message_type = action.payload;
    },
    changeMenuStatus: (state, action) => {
      state.menuStatus = action.payload;
    },
    changeApplicantData: (state, action) => {
      state.applicantData = action.payload;
    },
    onAddRefreshStatus: (state, action) => {
      state.refresh_status = action.payload;
    },
    onChangeFilterStatusObject: (state, action) => {
      state.filterStatusObj = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onChangeJobPageNumber,
  onChangeTotalJobCount,
  onChangeUserData,
  onChangeAccessDataById,
  onChangeAccessData,
  onRemoveSubmissionData,
  onChangeSubmissionPageNumber,
  onUpdateStatus,
  getInitialSubmissionData,
  changeInitialLoader,
  changeIsLoadingInModal,
  changeIsChangeResumeInApplicant,
  changeSelectedReportType,
  changeUserDetail,
  changeSearchLoading,
  onChangeApplicantPagenumber,
  removeSearchApplicantData,
  changeApplicantData,
  changeSearchText,
  getSearchApplicantData,
  updatePartnerData,
  removeJObData,
  onChangePageNumber,
  getVisibilityForAdmin,
  deleteSubmission,
  getNotificationData,
  getsubmissionData,
  getDashBoardData,
  getApplicantData,
  getjobData,
  getPartnerData,
  getClientData,
  getRecruiterData,
  getLevelData,
  getSkillData,
  addJobData,
  deletejob,
  deleteApplicant,
  getVisibility,
  is_active,
  changeModalStatus,
  changeJobStatus,
  updateApplicantData,
  updateJobData,
  addSubmissionData,
  updateSubmissionStatus,
  updateSubmissionTracker,
  addSingleApplicantData,
  changeResumeLink,
  getApplicantStatus,
  changeUserName,
  changeAlertMessageType,
  changeMenuStatus,
  updateSubmissionData,
  onAddRefreshStatus,
  onChangeFilterStatusObject,
} = ReduxStore.actions;

export default ReduxStore.reducer;
