import DashBoardView from "../../component/View/DashBoardView";
import JobsView from "../../component/View/JobsView";
import ApplicantsView from "../../component/View/ApplicantsView";
import PartnerView from "../../component/View/PartnerView";
import ClientView from "../../component/View/ClientView";
import LevelView from "../../component/View/LevelView";
import RecruitersView from "../../component/View/RecruitersView";
import SubmissionView from "../../component/View/SubmissionView";
import {
  APPLICANTS,
  CLIENT,
  DAILY_REPORT,
  DASHBOARD,
  DOMAIN,
  GROUP,
  JOBS,
  LEVEL,
  PARTNER,
  RECRUITERS,
  REPORT,
  SUBMISSION,
  VIEW_STATUS,
} from "../../Utils/enum";
import ReportView from "../../component/View/ReportView";
import RecruiterGroup from "../../component/View/RecruiterGroup";
import DomainView from "../../component/View/DomainView";
import DailyReportView from "../../component/DailyReport/DailyReportView";

const GetView = (status, onClick, isLoading) => {
  if (status === DASHBOARD) {
    localStorage.setItem(VIEW_STATUS, DASHBOARD);
    return (
      <DashBoardView
        isLoading={isLoading}
        status={DASHBOARD}
        onClick={(e) => onClick(e)}
      />
    );
  }
  if (status === RECRUITERS) {
    localStorage.setItem(VIEW_STATUS, RECRUITERS);
    return <RecruitersView />;
  }
  if (status === JOBS) {
    localStorage.setItem(VIEW_STATUS, JOBS);
    return <JobsView />;
  }
  if (status === SUBMISSION) {
    localStorage.setItem(VIEW_STATUS, SUBMISSION);
    return <SubmissionView />;
  }
  if (status === APPLICANTS) {
    localStorage.setItem(VIEW_STATUS, APPLICANTS);
    return <ApplicantsView />;
  }
  if (status === PARTNER) {
    localStorage.setItem(VIEW_STATUS, PARTNER);
    return <PartnerView />;
  }
  if (status === DOMAIN) {
    localStorage.setItem(VIEW_STATUS, DOMAIN);
    return <DomainView />;
  }

  if (status === CLIENT) {
    localStorage.setItem(VIEW_STATUS, CLIENT);
    return <ClientView />;
  }
  if (status === LEVEL) {
    localStorage.setItem(VIEW_STATUS, LEVEL);
    return <LevelView />;
  }
  if (status === DAILY_REPORT) {
    localStorage.setItem(VIEW_STATUS, DAILY_REPORT);
    return <DailyReportView />;
  }
  if (status === REPORT) {
    localStorage.setItem(VIEW_STATUS, REPORT);
    return <ReportView />;
  }
  if (status === GROUP) {
    localStorage.setItem(VIEW_STATUS, GROUP);
    return <RecruiterGroup />;
  }
};

export default GetView;
