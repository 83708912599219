import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";

export default function PasswordInputField(props) {
  const [isViewPass, setIsViewPass] = useState(false);

  return (
    <div className="user-box">
      <label>Password</label>
      <input
        placeholder="Password *"
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value || ""}
        type={isViewPass ? "password" : "text"}
        id="password"
        required=""
      />
      <span className="password-toggle-icon">
        <Icon
          onClick={() => setIsViewPass(!isViewPass)}
          icon={isViewPass ? CommonIcon.eye : CommonIcon.visibility_off}
        />
      </span>
    </div>
  );
}
